// for the API url and Front URL will be Here
export const localWwwUrl = 'http://localhost:3004'
export const LiveWwwUrl = 'https://administrator.emilo.in'
export const gCaptcahSiteKey = "6Lfut2kqAAAAAJt9Y-nkImpo8K_uvUXIXAaGMEJw";
export const localApiUrl = 'http://localhost:3005/administrator/'
const LiveApiUrl = 'https://api.emilo.in/administrator/'

export const BaseImageUrl = 'https://s3aws.emilo.in/images'

export const BaseGarbageImageUrl = 'https://api.emilo.in/garbage/output_images'

export const BaseHelperUrl = 'https://api.emilo.in/helper'

let BaseURLFinal = LiveWwwUrl
let BaseAPIURLFinal = LiveApiUrl

if (window.location.href.includes(localWwwUrl)) {
    BaseURLFinal = localWwwUrl
    BaseAPIURLFinal = localApiUrl
}

export const WwwUrl = BaseURLFinal
export const ApiUrl = BaseAPIURLFinal
export const frontPanelURL = 'https://emilorj.sjain.io'

export function GetFullAPIUrl(slug) {
    return `${ApiUrl}${slug}`;
}

// ####################### ADMIN PANEL #####################################

// for the admin panel API will be here
export const adminLoginUrl = `${ApiUrl}api/login`
export const verifyCaptchaUrl = `${ApiUrl}api/login/verify-captcha`
export const adminLoginCheckUrl = `${ApiUrl}api/login/check`
export const adminCMSListUrl = `${ApiUrl}api/pages/listAll`
export const adminCMSSaveUrl = `${ApiUrl}api/pages/savePage`
export const adminLoginLogUrl = `${ApiUrl}api/login/logs`
export const adminCreate = `${ApiUrl}api/login/createAdmin`

//for the Category
export const adminCategoryUrl = `${ApiUrl}api/categories/listAll`
// getData....
export const adminCategoryGetDataUrl = `${ApiUrl}api/categories/getData`
export const adminCategoryUpdateDataUrl = `${ApiUrl}api/categories/updateData`
export const adminCategoryAddDataUrl = `${ApiUrl}api/categories/addData/`

// for the Report Anythings
export const reportAnythingsDataUrl = `${ApiUrl}api/reportAnythings/listAll`
export const reportAnythingsStatusUrl = `${ApiUrl}api/reportAnythings/updateData`
// for the Report Anythings

//for the Keyworld
export const adminKeywordUrl = `${ApiUrl}api/Keywords/listAll`
// getData....
export const adminKeywordGetDataUrl = `${ApiUrl}api/keywords/getData`
export const adminKeywordUpdateDataUrl = `${ApiUrl}api/keywords/updateData`
export const adminKeywordAddDataUrl = `${ApiUrl}api/keywords/addData/`

// for the topics

export const adminTopicsUrl = `${ApiUrl}api/topics/listAll`
// getData....
export const adminTopicsGetDataUrl = `${ApiUrl}api/topics/getData`
export const adminTopicsUpdateDataUrl = `${ApiUrl}api/topics/updateData`
export const adminTopicsAddDataUrl = `${ApiUrl}api/topics/addData/`

// hashtag hashtag

export const adminHashtagcsUrl = `${ApiUrl}api/hashtag/listAll`
// getData....
export const adminHashtagGetDataUrl = `${ApiUrl}api/hashtag/getData`
export const adminHashtagUpdateDataUrl = `${ApiUrl}api/hashtag/updateData`
export const adminHashtagAddDataUrl = `${ApiUrl}api/hashtag/addData/`

// for the admin details Page Start
export const GenerateSecretAdminUrl = `${ApiUrl}api/login/generate-secret`
export const VerifyTokenAdminUrl = `${ApiUrl}api/login/verify-token`
export const AdminloginHistorysUrl = `${ApiUrl}api/login/loginHistory`
// for the admin details Page End

// for the user details Page Start
export const ListUsersUrl = `${ApiUrl}api/user/listAll`
export const detailsUsersUrl = `${ApiUrl}api/user/details`
export const UserloginHistorysUrl = `${ApiUrl}api/user/loginHistory`
export const UserProfileDataUrl = `${ApiUrl}api/user/profileData`
export const UserPhotoNVideoDataUrl = `${ApiUrl}api/user/PhotoNVideo`
export const DashboardDataUrl = `${ApiUrl}api/loginshboardData`
export const listReportsUrl = `${ApiUrl}api/reports/listAll`
// for the user details Page Start

// for the  user limitation post
export const limitationUrl = `${ApiUrl}api/limit/list/6669793f3cb921003e08ad4f`
export const limitationUpdateUrl = `${ApiUrl}api/limit/update/6669793f3cb921003e08ad4f`

// for the  user limitation Photo
export const limitationPhotoUrl = `${ApiUrl}api/limit/list/666fecb718ce607ac52f2fe9`
export const limitationPhotoUpdateUrl = `${ApiUrl}api/limit/update/666fecb718ce607ac52f2fe9`

// for  the  user limitation video

export const limitationVideoUrl = `${ApiUrl}api/limit/list/66701166f06468cce926f331`
export const limitationVideoUpdateUrl = `${ApiUrl}api/limit/update/66701166f06468cce926f331`
//66702a327d1b74bd7f8e6934

export const UserReportslimitationurl = `${ApiUrl}api/limit/list/66702a327d1b74bd7f8e6934`
export const UserReportslimitationUpdateurl = `${ApiUrl}api/limit/update/66702a327d1b74bd7f8e6934`

// for the admin panel API will be here
export const adsCampaigns = `${ApiUrl}api/ads/adsCampaigns`
export const listOfAllUsers = `${ApiUrl}api/user/listAll` 
export const softcornerAlluser= `${ApiUrl}api/softcorner/alluser`  
export const  sowftconerdeails= `${ApiUrl}api/softcorner/sowftconerdeails`  


//APIs for data visualization
export const visualization_countdata = `${ApiUrl}api/count/countdata`
export const visualization_monthreport = `${ApiUrl}api/count/monthreport`

export const visualization_userlocation = `${ApiUrl}api/count/userlocation`
export const visualization_postelements = `${ApiUrl}api/count/postelements`

//api  for  password change 
 export  const   passwordChangeApi = `${ApiUrl}api/login/forgetpassword`

// ####################### ADMIN PANEL #####################################

// ####################### USER PANEL #####################################

export function getWwwToken(type) {
    let sendValue = localStorage.getItem('deviceDetail')
    if (type === 1) {
        sendValue = btoa(sendValue)
    }
    return sendValue
}
// for the API url and Front URL will be Here

const AllMonthsName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nove', 'Dec']
const AllDaysName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export function showFulldatetimein(dateString) {
    let timestamp = new Date(dateString)
    timestamp = timestamp.getTime()
    const days = AllDaysName
    const tmp = new Date(Number(timestamp))
    var minutes = tmp.getMinutes()
    var hours = tmp.getHours()
    const dayName = days[tmp.getDay()]
    if (minutes < 10) {
        minutes = `0${minutes}`
    }
    var AmPm = 'am'
    if (hours > 11) {
        AmPm = 'pm'
    }
    if (hours > 12) {
        hours = hours - 12
    }
    if (hours < 10) {
        hours = `0${hours}`
    }
    return `${tmp.getDate()} ${AllMonthsName[tmp.getMonth()]}, ${tmp.getUTCFullYear()} (${hours}:${minutes} ${AmPm}) - ${dayName}`
}

export function showDateTimestamp(timestamp) {
    const days = AllDaysName
    const tmp = new Date(Number(timestamp))
    var minutes = tmp.getMinutes()
    var hours = tmp.getHours()
    const dayName = days[tmp.getDay()]
    if (minutes < 10) {
        minutes = `0${minutes}`
    }
    var AmPm = 'am'
    if (hours > 11) {
        AmPm = 'pm'
    }
    if (hours > 12) {
        hours = hours - 12
    }
    if (hours < 10) {
        hours = `0${hours}`
    }
    return `${tmp.getDate()} ${AllMonthsName[tmp.getMonth()]}, ${tmp.getUTCFullYear()} (${hours}:${minutes} ${AmPm}) - ${dayName}`
}

export function logoutAll() {
    localStorage.removeItem('pgToken')
    localStorage.removeItem('pgAToken')
    localStorage.clear()
}

export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

export const ShowCurrency = (index, array) => {
    let temp = ''
    if (array) {
        temp = array.find((item) => item.id === index)
    }
    return temp && temp.symbol ? temp.symbol : ''
}

export function ShowAmountFormat(value) {
    return value !== '' ? Number(value).toLocaleString() : ''
}

export function getTodayDate() {
    const tmp = new Date()
    var month = tmp.getMonth() + 1
    if (month < 10) {
        month = `0${month}`
    }
    var date = tmp.getDate()
    if (date < 10) {
        date = `0${date}`
    }
    return `${tmp.getUTCFullYear()}-${month}-${date}`
}

export const handleCopyClick = (text) => {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea')
    // Set the value of the textarea to the text you want to copy
    textarea.value = text
    // Append the textarea to the document
    document.body.appendChild(textarea)
    // Select the text inside the textarea
    textarea.select()
    // Execute the copy command
    document.execCommand('copy')
    // Remove the temporary textarea from the document
    document.body.removeChild(textarea)
    // console.log('Text copied to clipboard:', text)
    return true
}

export function scrollToDivId(divID) {
    const targetDiv = document.getElementById(divID)
    if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: 'smooth' })
    }
}
export function ConvertDatetoYMD(dataString) {
    const tmp = new Date(dataString)
    var month = tmp.getMonth() + 1
    if (month < 10) {
        month = `0${month}`
    }
    var date = tmp.getDate()
    if (date < 10) {
        date = `0${date}`
    }
    return `${tmp.getUTCFullYear()}-${month}-${date}`
}

// Function to convert array of objects to CSV
// const arrayToCSV = (array) => {
//     const headers = Object.keys(array[0]).join(',')
//     const rows = array.map((obj) => Object.values(obj).join(',')).join('\n')
//     return `${headers}\n${rows}`
// }
const arrayToCSV = (array) => {
    if (!array.length) return '';

    const headers = Object.keys(array[0]).join(',');

    const rows = array.map((obj) =>
        Object.values(obj)
            .map((value) => {
                let stringValue = String(value); // Convert value to string
                if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
                    // Escape double quotes and wrap in quotes
                    stringValue = `"${stringValue.replace(/"/g, '""')}"`;
                }
                return stringValue;
            })
            .join(',')
    ).join('\n');

    return `${headers}\n${rows}`;
};

// Function to trigger CSV file download
export const arrayToCSVFile = (data, filename) => {
    const csvString = arrayToCSV(data)
    const blob = new Blob([csvString], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('hidden', '')
    a.setAttribute('href', url)
    a.setAttribute('download', `${filename}.csv`)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

export function consoleLog(error, fileName) {
    console.log(fileName)
    console.error(error)
    console.log(fileName)
}

export function GetUserProfileUrl(UserData) {
    return `${frontPanelURL}/profile/${UserData.username ? UserData.username : UserData._id}`
}

export function GetUserName(UserData) {
    return UserData.username ? UserData.username : UserData._id
}

export function headerAPI(headerObject) {
    return {
        ...headerObject,
        Authorization: `Bearer ${localStorage.getItem('pgAToken')}`,
        device: localStorage.getItem('deviceDetail') ? localStorage.getItem('deviceDetail') : 'unknown',
        userip: localStorage.getItem('deviceIp') ? localStorage.getItem('deviceIp') : 0,
        devicetype: localStorage.getItem('deviceOs') ? localStorage.getItem('deviceOs') : 'unknown',
        versioncode: '0.0.2',
        locuserlong: localStorage.getItem('LocUserLong') ? localStorage.getItem('LocUserLong') : 0,
        locuserlat: localStorage.getItem('LocUserLat') ? localStorage.getItem('LocUserLat') : 0,
        locusermsg: localStorage.getItem('LocUserMsg') ? localStorage.getItem('LocUserMsg') : 'NA',
        locusercity: localStorage.getItem('localityCity') ? localStorage.getItem('localityCity') : 'unknown',
        'Content-Type': 'application/json',
        'www-api-token': getWwwToken(1),
        // Authorization: `Bearer ${localStorage.getItem('pgAToken')}`,
    }
}

export function showIPInfo(IP) {
    return IP && IP !== 0 && IP !== '0' ? `<a target="_BLANK" rel="noreferrer" href='https://www.infobyip.com/ip-${IP}.html'>${IP}</a>` : 'NA'
}

export function showLatLongInfo(lat, long, city) {
    let returnValue = ''
    if (lat && Number(lat) !== 0 && long && Number(long) !== 0) {
        returnValue = `<a target="_BLANK" rel="noreferrer" href='https://www.google.com/maps/place?q=${lat},${long}'>${city ? city : `${lat}, ${long}`}</a>`
    } else if (city && city !== '' && city !== 'unknown') {
        returnValue = `<a target="_BLANK" rel="noreferrer" href='https://www.google.com/maps/place?q=${city}'>${city}</a>`
    } else {
        returnValue = city
    }
    return returnValue
}

export const TimeBetweenDates = (date1, date2) => {
    // Parse the date strings into Date objects
    const dateObj1 = new Date(date1)
    const dateObj2 = new Date(date2)

    // Calculate the difference in milliseconds
    const differenceInMs = Math.abs(dateObj1 - dateObj2)

    // Convert the difference to a more readable format
    const msInMinute = 60 * 1000
    const msInHour = 60 * msInMinute
    const msInDay = 24 * msInHour

    const days = Math.floor(differenceInMs / msInDay)
    const hours = Math.floor((differenceInMs % msInDay) / msInHour)
    const minutes = Math.floor((differenceInMs % msInHour) / msInMinute)
    const seconds = Math.floor((differenceInMs % msInMinute) / 1000)
    // {hours} hours, {minutes} minutes, {seconds} seconds
    // return differenceInMs / 1000;
    return date1 !== date2 ? `${days && days > 0 ? `${days} days, ` : ''}${hours && hours > 0 ? `${hours} hours, ` : ''}${minutes && minutes > 0 ? `${minutes} minutes, ` : ''}${minutes && seconds > 0 ? `${seconds} seconds` : ''}` : 'NA'
}
